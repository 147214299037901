import React from "react";
import { ImageComponent } from "@/components/elements/Image";
import SocialLink from "@/components/elements/SocialLink";
import NavButton from "@/components/elements/NavButton";
import LinksGroup from "@/components/elements/LinksGroup";
import StatusBadge from "@/components/elements/StatusBadge";

interface Props {
  project: PortfolioProjectType;
}

function ProjectDetailsSection({ project }: Props) {
  const websiteDetails = project.project_socials.find(
    (social) => social.type === "website"
  );

  const projectLogoDimensions = {
    width: project?.project_logo?.filename
      ? Number.parseInt(
          project?.project_logo?.filename.split("/")[5]?.split("x")[0]
        )
      : 0,
    height: project?.project_logo?.filename
      ? Number.parseInt(
          project.project_logo.filename.split("/")[5]?.split("x")[1]
        )
      : 0,
  };

  return (
    <section className="pt-8 px-4 lg:pt-16 max-xl:pb-16 pb-24 mx-auto max-w-x-big">
      <div className="flex items-start max-xl:flex-col max-xl:items-center">
        <NavButton
          title="Back to portfolio page"
          href="/#portfolio"
          className="flex-1"
        />
        <div className="max-xl:my-4 px-4 mb-16">
          {project.project_logo.filename && (
            <img
              className="rounded-2xl overflow-hidden mb-7 m-auto h-auto"
              style={{ maxWidth: "140px" }}
              src={project.project_logo.filename}
              alt={project.project_logo.alt || project.project_name}
              width={projectLogoDimensions.width}
              height={projectLogoDimensions.height}
            />
          )}
          <h1 className="font-bold text-center leading-snug text-8xl">
            {project.project_name}
          </h1>
          <p className="max-w-2xl m-auto text-lg py-8 text-center">
            {project.project_description}
          </p>
          {websiteDetails && (
            <a
              className="mx-auto block bg-secondary text-white rounded-lg px-14 py-4 xl:px-12 relative xl:whitespace-pre border-secondary border-2 hover:text-secondary hover:bg-transparent transition-all max-sm:mb-0 max-w-max font-semibold text-lg"
              href={websiteDetails.url}
              target="_blank"
              rel="noreferrer"
            >
              Visit website
            </a>
          )}
        </div>
        <div className="flex-1 flex justify-center flex-col items-end gap-4">
          <ul className="flex justify-center max-xl:mt-4 max-xl:mb-8 flex-col gap-4 max-xl:flex-row max-xl:gap-6">
            {project.project_socials.map((social) => (
              <li
                key={social._uid}
                className="w-10 h-10 p-2 bg-primary text-primary last:mr-0 rounded-full"
              >
                <SocialLink {...social} className="text-white" />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="relative max-w-3xl rounded-2xl overflow-hidden mb-16 m-auto">
        <ImageComponent
          objectFit="fill"
          data={project.project_image}
          alt={project.project_name}
          fluid
        />
        {project.project_status && (
          <StatusBadge status={project.project_status} />
        )}
      </div>
      <div>
        {project.project_ceo.length > 0 && (
          <p className="text-center">
            <strong>CEO:</strong>
            <LinksGroup links={project.project_ceo} />
          </p>
        )}
        {project.project_founders.length > 0 && (
          <p className="text-center">
            <strong>Founders:</strong>
            <LinksGroup links={project.project_founders} />
          </p>
        )}
        {project.project_investment_lead?.length > 0 && (
          <p className="text-center">
            <strong>Investment Lead:</strong>
            <LinksGroup links={project.project_investment_lead} />
          </p>
        )}
        {project.project_acquiredBy && (
          <p className="text-center mt-8">
            Acquired by {project.project_acquiredBy}
          </p>
        )}
      </div>
    </section>
  );
}

export default ProjectDetailsSection;
